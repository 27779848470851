import Sidebar from "./Sidebar";
import React, {FunctionComponent} from "react";
import {SettingsProps} from "../interfaces/SettingsProps";
import {connect} from "react-redux";
import {getNewApplicationTemplates} from "../helpers/settingsHelpers";
import {NewApplicationTemplate} from "../../applications/applicant/models/NewApplicationTemplate";
import DropdownInputField from "./input-fields/DropdownInputField";
import {LocalizeContextProps, withLocalize} from "react-localize-redux";
// @ts-ignore
import {useLocalStorage} from "usehooks-ts";

type NewApplicationTemplateItem = {name: string, value: NewApplicationTemplate | null}
export const USER_SETTINGS_SIDEBAR_ID = 'user-settings-sidebar';
const UserSettingsSidebar: FunctionComponent<SettingsProps & LocalizeContextProps> = (props) => {
    const customTemplatesItems: NewApplicationTemplateItem[] = getNewApplicationTemplates(props);
    const userTemplateItems: NewApplicationTemplateItem = {name: props.translate('MY_DEFAULT').toString(), value: null};
    const [selectedTemplate, setSelectedTemplate] = useLocalStorage<string | null>(
        'customNewApplicationTemplate', 
        null, {
            serializer: v => v || 'null',
            deserializer: v => v
        }
    );
    
    return (
        <Sidebar 
            id={USER_SETTINGS_SIDEBAR_ID} 
            position="right"
            title={<h4>{props.translate('USER_SETTINGS')}</h4>}
            style={{padding: '8px 16px'}}
            width={250}
            closeIcon
        >            
            <div>
                <DropdownInputField<NewApplicationTemplateItem>
                    name="newApplicationTemplates"
                    description={props.translate('NEW_APPLICATION_TEMPLATE')}
                    editMode={true}
                    style={{background: 'white'}}
                    keyValue={item => JSON.stringify(item.value)}
                    displayValue={item => item.name}
                    value={selectedTemplate}
                    items={customTemplatesItems.concat(userTemplateItems)}
                    onValueChanged={template => setSelectedTemplate(template as string)}
                />
            </div>
        </Sidebar>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer
});
export default connect<SettingsProps, {}, {}, any>(mapStateToProps)(withLocalize(UserSettingsSidebar));