import React, {FunctionComponent, useState} from "react";
import "./CampaignInfo.css";
import TextInputField from "../../../../common/components/input-fields/TextInputField";
import {CampaignViewDataState} from "../../../reducers/campaignViewDataReducer";
import {Dropdown} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faEnvelope, faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import {NavigateFunction, useNavigate} from "react-router";
import {deleteCampaign as deleteCampaignAPI} from "../../../api/deleteCampaign";
import {handleCommandResult} from "../../../../common/helpers/CommandResult";
import {showToastMessage, ShowToastMessageProps} from "../../../../common/actions/ToastMessagesActionCreator";
import {CampaignStatus, CampaignTestModel, CampaignType} from "../../../../common/models/Campaign";
import {UserProps} from "../../../../common/interfaces/UserProps";
import {CampaignViewDataActionCreators, CampaignViewDataActions} from "../../../actions/CampaignViewDataActionCreators";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import TestCampaignModal from "./TestCampaignModal";
import {validateCampaign} from "../helpers/validators";
import {getCustomerCommunicationConfig} from "../../../../common/helpers/settingsHelpers";
import {SettingsProps} from "../../../../common/interfaces/SettingsProps";
import {testCampaign as testCampaignAPI} from "../../../api/testCampaign";

type CampaignInfoStateProps = CampaignViewDataState & LocalizeContextProps & SettingsProps & UserProps;

type CampaignInfoDispatchProps = ShowToastMessageProps & CampaignViewDataActions;

interface CampaignInfoProps extends CampaignInfoStateProps, CampaignInfoDispatchProps {
    createMode: boolean;
}

const CampaignInfo: FunctionComponent<CampaignInfoProps> = (props) => {
    const navigate = useNavigate();
    const [testModalVisible, setTestModalVisible] = useState<boolean>(false);
    const config = getCustomerCommunicationConfig(props);
    
    if (!props.campaign) {
        return null;
    }
    
    const openTestCampaignModal = () => {
        const errorsNames = validateCampaign(props.campaign!, config);
        if (errorsNames.length) {
            props.setErrors(errorsNames.reduce((errors, name) => ({...errors, [name]: true}), {}));
            props.setActiveTab('design');
        } else {
            setTestModalVisible(true);
        }
    }
    
    return (
        <div className="campaign-info">
            <div className="campaign-name uppercase">
                <FontAwesomeIcon 
                    style={{marginRight: '8px'}}
                    icon={props.campaign.type === CampaignType.SMS ? faMobileAlt : faEnvelope}
                />
                {props.campaign.name}
            </div>
            <div className="campaign-details">
                {renderCreatedAt(props)}
                {renderSentAt(props)}
                {renderOwner(props)}
                {props.campaign.status === CampaignStatus.SENT && !props.createMode &&
                    renderTextInputField('recipients', props.campaign.recipients || 0, 'CAMPAIGNS_VIEW.TABLE_HEADERS.RECIPIENTS')
                }
                {props.campaign.status === CampaignStatus.SENT && !props.createMode &&
                    renderTextInputField('delivered', props.campaign.delivered || 0, 'CAMPAIGNS_VIEW.TABLE_HEADERS.DELIVERED')
                }
                {props.campaign.status === CampaignStatus.SENT && !props.createMode &&
                    renderTextInputField(
                        'engagements', 
                        props.campaign.engagements || 0, 
                        props.campaign.type === CampaignType.SMS ? 'REPLIES' : 'CLICKS'
                    )
                }
            </div>
            <Dropdown drop="down" align="end">
                <Dropdown.Toggle id="application-menu" className="btn-white-bg" style={{ padding: '3px 12px' }}>
                    <FontAwesomeIcon icon={faBars} />
                </Dropdown.Toggle>
                <div className="dropdown-arrow-down" />
                <Dropdown.Menu>
                    {!props.createMode &&
                        <Dropdown.Item 
                            key="copy-campaign"
                            onClick={() => copyCampaign(props, navigate)}
                        >
                            <Translate id="COPY"/>
                        </Dropdown.Item>
                    }
                    {!props.createMode && props.campaign.type === CampaignType.SMS &&
                        <Dropdown.Item key="copy-campaign-email" onClick={() => copyCampaignAs(CampaignType.EMAIL, props, navigate)}>
                            <Translate id="COPY_AS_EMAIL"/>
                        </Dropdown.Item>
                    }
                    {!props.createMode && props.campaign.type === CampaignType.EMAIL &&
                        <Dropdown.Item key="copy-campaign-sms" onClick={() => copyCampaignAs(CampaignType.SMS, props, navigate)}>
                            <Translate id="COPY_AS_SMS"/>
                        </Dropdown.Item>
                    }
                    {props.campaign.status === CampaignStatus.DRAFT &&
                        <Dropdown.Item key="test-campaign" onClick={() => openTestCampaignModal()}>
                            <Translate id="TEST"/>
                        </Dropdown.Item>
                    }
                    {props.campaign.status === CampaignStatus.DRAFT && props.campaign.id && 
                        <Dropdown.Item
                            key="delete-campaign"
                            onClick={() => deleteCampaign(props, navigate)}
                            style={{color: '#F05B59'}}
                        >
                            <Translate id="DELETE"/>
                        </Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
            <TestCampaignModal 
                campaign={props.campaign}
                user={props.userData.user}
                show={testModalVisible}
                onTest={campaign => {testCampaign(campaign, props); setTestModalVisible(false);}}
                onHide={() => setTestModalVisible(false)}
            />
        </div>
    );
}

const renderCreatedAt = (props: CampaignInfoProps) => {
    const createdAt = props.createMode ? new Date() : new Date(props.campaign!.createdAt);
    return renderTextInputField('createdAt', createdAt.toLocaleDateString(), 'CREATED_ON');
}

const renderOwner = (props: CampaignInfoProps) => {
    const owner = props.createMode ? props.userData.user!.displayName : props.campaign!.owner;
    return renderTextInputField('owner', owner, 'CREATOR');
}

const renderSentAt = (props: CampaignInfoProps) => {
    if (props.createMode || !props.campaign!.sentAt) {
        return null;
    }
    const sentAt = new Date(props.campaign!.sentAt).toLocaleDateString();
    return renderTextInputField('sentAt', sentAt, 'CAMPAIGNS_VIEW.TABLE_HEADERS.START_DATE');
}

const renderTextInputField = (name: string, value: string | number, descriptionKey: string) => (
    <div>
        <TextInputField 
            name={name} 
            value={typeof value === 'number' ? value.toString(10) : value}
            descriptionKey={descriptionKey}
        />
    </div>
)

const copyCampaignAs = (type: CampaignType, props: CampaignInfoProps, navigate: NavigateFunction) => {
    props.editCampaign({id: undefined, status: CampaignStatus.DRAFT, template: undefined, type});
    navigate(`/campaign/${props.campaign!.id}/copy`);
}

const copyCampaign = (props: CampaignInfoProps, navigate: NavigateFunction) => {
    props.editCampaign({id: undefined, status: CampaignStatus.DRAFT});
    navigate(`/campaign/${props.campaign!.id}/copy`);
}

const deleteCampaign = (props: CampaignInfoProps, navigate: NavigateFunction) => {
    deleteCampaignAPI(props.campaign!.id!).then(result => {
        handleCommandResult(result, props, 'CAMPAIGNS_VIEW.CAMPAIGN_DELETED');
        navigate(`/campaigns`);
    });
}

const testCampaign = (campaign: CampaignTestModel, props: CampaignInfoProps) => {
    testCampaignAPI(campaign).then(result => handleCommandResult(result, props, 'TEST_MESSAGE_SENT'));
}

const mapStateToProps = (state: any) => ({
    ...state.campaignViewDataReducer,
    ...state.settingsActionsReducer,
    ...state.userActionsReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    showToastMessage,
    ...CampaignViewDataActionCreators
}, dispatch);
export default connect<CampaignInfoStateProps, CampaignInfoDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(CampaignInfo));