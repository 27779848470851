import {faCircle, faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {CSSProperties, FunctionComponent} from "react";
import {USER_SETTINGS_SIDEBAR_ID} from "./UserSettingsSidebar";
import {ToggleSidebarActionCreator, ToggleSidebarActionProps} from "../actions/SidebarActionCreator";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// @ts-ignore
import {useLocalStorage} from "usehooks-ts";

const UserSettingsButton: FunctionComponent<ToggleSidebarActionProps> = (props) => {
    const [customNewApplicationTemplate] = useLocalStorage<string | null>('customNewApplicationTemplate', null);
    const hasCustomSettings = customNewApplicationTemplate !== null;
    const customSettingsIndicatorStyle: CSSProperties = {
        display: hasCustomSettings ? 'block' : 'none',
        color: '#559AF4',
        fontSize: '8px',
        position: 'absolute',
        top: '-2px',
        right: '-3px'
    }
    
    return (
        <div style={{position: 'relative'}}>
            <FontAwesomeIcon
                style={customSettingsIndicatorStyle}
                icon={faCircle}
                onClick={() => props.toggleSidebar(USER_SETTINGS_SIDEBAR_ID)}
            />
            <FontAwesomeIcon
                style={{cursor: 'pointer'}}
                icon={faCog}
                onClick={() => props.toggleSidebar(USER_SETTINGS_SIDEBAR_ID)}
            />
        </div>
    )
}

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<{}, ToggleSidebarActionProps, {}, any>(null, mapActionCreatorsToProps)(UserSettingsButton);