import { Action, Dispatch } from 'redux';
import {ApplicantsGroupBy} from "../models/ApplicantsGroupBy";
import {ApplicantsViewFilter} from "../components/ApplicantsView";
import {ProductType} from "../../../common/models/ProductType";

export const SET_PAGE = 'SET_PAGE';
export const SET_PAGESIZE = 'SET_PAGESIZE';
export const SET_ORDER = 'SET_ORDER';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_LOAN_STATUS = 'SET_LOAN_STATUS';
export const SET_FOLLOW_UP = 'SET_FOLLOW_UP';
export const SET_BANK = 'SET_BANK';
export const SET_ADVERTISEMENTS = 'SET_ADVERTISEMENTS';
export const SET_APPLICATION_SOURCES = 'SET_APPLICATION_SOURCES';
export const SET_SUBSTATUSES = 'SET_SUBSTATUSES';
export const SET_PRIMARY_LANGUAGE = 'SET_PRIMARY_LANGUAGE';
export const SET_TEAM_ID = 'SET_TEAM_ID';
export const SET_EXCLUDED_TEAM_ID = 'SET_EXCLUDED_TEAM_ID';
export const SET_LAST_STATUS_CHANGE_FROM = 'SET_LAST_STATUS_CHANGE_FROM';
export const SET_LAST_STATUS_CHANGE_TO = 'SET_LAST_STATUS_CHANGE_TO';
export const SET_FOLLOW_UP_DATE_FROM = 'SET_FOLLOW_UP_DATE_FROM';
export const SET_FOLLOW_UP_DATE_TO = 'SET_FOLLOW_UP_DATE_TO';
export const SET_PROPERTY_REQUIRED = 'SET_PROPERTY_REQUIRED';
export const SET_AMOUNT_FROM = 'SET_AMOUNT_FROM';
export const SET_AMOUNT_TO = 'SET_AMOUNT_TO';
export const SET_REFINANCED_AMOUNT_FROM = 'SET_REFINANCED_AMOUNT_FROM';
export const SET_REFINANCED_AMOUNT_TO = 'SET_REFINANCED_AMOUNT_TO';
export const SET_NOMINAL_INTEREST_FROM = 'SET_NOMINAL_INTEREST_FROM';
export const SET_PUBLISHER_ID = 'SET_PUBLISHER_ID';
export const SET_CUSTOMER_ADVISORS_IDS = 'SET_CUSTOMER_ADVISORS_IDS';
export const SET_GROUP_BY = 'SET_GROUP_BY';
export const RESET_TABLE_STATE = 'RESET_TABLE_STATE';

interface SetPageAction extends Action<typeof SET_PAGE> {
    page: number;
}

interface SetPageSizeAction extends Action<typeof SET_PAGESIZE> {
    pageSize: number;
}

interface SetOrderAction extends Action<typeof SET_ORDER> {
    orderBy: number;
    orderDirection: number;
}

interface SetProductsAction extends Action<typeof SET_PRODUCTS> {
    products: ProductType[];
}

interface SetLoanStatusAction extends Action<typeof SET_LOAN_STATUS> {
    loanStatus: number | null;
}

interface SetFollowUpAction extends Action<typeof SET_FOLLOW_UP> {
    followUp: number | null;
}

interface SetBankAction extends Action<typeof SET_BANK> {
    bankId: number | null;
}

interface SetAdvertisementsAction extends Action<typeof SET_ADVERTISEMENTS> {
    advertisementIds: number[];
    advertisementSiteIds: number[];
}

interface SetApplicationSourcesAction extends Action<typeof SET_APPLICATION_SOURCES> {
    applicationSourceIds: number[];
}

interface SetSubstatusesAction extends Action<typeof SET_SUBSTATUSES> {
    substatusIds: number[];
}

interface SetPrimaryLanguage extends Action<typeof SET_PRIMARY_LANGUAGE> {
    primaryLanguage: string | null;
}

interface SetTeamId extends Action<typeof SET_TEAM_ID> {
    teamId: number | null;
}

interface SetExcludedTeamId extends Action<typeof SET_EXCLUDED_TEAM_ID> {
    excludedTeamId: number | null;
}

interface SetLastStatusChangeFrom extends Action<typeof SET_LAST_STATUS_CHANGE_FROM> {
    lastStatusChangeFrom: Date | null;
}

interface SetLastStatusChangeTo extends Action<typeof SET_LAST_STATUS_CHANGE_TO> {
    lastStatusChangeTo: Date | null;
}

interface SetFollowUpDateFrom extends Action<typeof SET_FOLLOW_UP_DATE_FROM> {
    followUpDateFrom: Date | null;
}

interface SetFollowUpDateTo extends Action<typeof SET_FOLLOW_UP_DATE_TO> {
    followUpDateTo: Date | null;
}

interface SetPropertyRequired extends Action<typeof SET_PROPERTY_REQUIRED> {
    propertyRequired: boolean | null;
}

interface SetAmountFrom extends Action<typeof SET_AMOUNT_FROM> {
    amountFrom: number | null;
}

interface SetAmountTo extends Action<typeof SET_AMOUNT_TO> {
    amountTo: number | null;
}

interface SetRefinancedAmountFrom extends Action<typeof SET_REFINANCED_AMOUNT_FROM> {
    refinancedAmountFrom: number | null;
}

interface SetRefinancedAmountTo extends Action<typeof SET_REFINANCED_AMOUNT_TO> {
    refinancedAmountTo: number | null;
}

interface SetNominalInterestFrom extends Action<typeof SET_NOMINAL_INTEREST_FROM> {
    nominalInterestFrom: number | null;
}

interface SetPublisherId extends Action<typeof SET_PUBLISHER_ID> {
    publisherId: string | null;
}

interface SetCustomerAdvisorsIds extends Action<typeof SET_CUSTOMER_ADVISORS_IDS> {
    customerAdvisorsIds: number[];
}

interface SetGroupBy extends Action<typeof SET_GROUP_BY> {
    groupBy: ApplicantsGroupBy;
}

interface ResetTableState extends Action<typeof RESET_TABLE_STATE> { }

export type SetApplicantsViewTableParamsAction = SetPageAction
    | SetPageSizeAction
    | SetOrderAction
    | SetProductsAction
    | SetLoanStatusAction
    | SetFollowUpAction
    | SetBankAction
    | SetAdvertisementsAction
    | SetApplicationSourcesAction
    | SetSubstatusesAction
    | SetPrimaryLanguage
    | SetTeamId
    | SetExcludedTeamId
    | SetLastStatusChangeFrom
    | SetLastStatusChangeTo
    | SetFollowUpDateFrom
    | SetFollowUpDateTo
    | SetPropertyRequired
    | SetAmountFrom
    | SetAmountTo
    | SetRefinancedAmountFrom
    | SetRefinancedAmountTo
    | SetNominalInterestFrom
    | SetPublisherId
    | SetCustomerAdvisorsIds
    | SetGroupBy
    | ResetTableState;

const createSetPageAction = (page: number): SetPageAction => ({ type: SET_PAGE, page });
const createSetPageSizeAction = (pageSize: number): SetPageSizeAction => ({ type: SET_PAGESIZE, pageSize });
const createSetProductsAction = (products: ProductType[]): SetProductsAction => ({ type: SET_PRODUCTS, products });
const createSetStatusAction = (status: number | null): SetLoanStatusAction => ({ type: SET_LOAN_STATUS, loanStatus: status });
const createSetFollowUpAction = (followUp: number | null): SetFollowUpAction => ({ type: SET_FOLLOW_UP, followUp });
const createSetBankAction = (bankId: number | null): SetBankAction => ({ type: SET_BANK, bankId });
const createSetOrderAction = (orderBy: number, orderDirection: number): SetOrderAction => ({ type: SET_ORDER, orderBy, orderDirection });
const createSetAdvertisementsAction = (advertisementSiteIds: number[], advertisementIds: number[]): SetAdvertisementsAction =>
    ({ type: SET_ADVERTISEMENTS, advertisementSiteIds, advertisementIds });
const createSetApplicationSourceIds = (applicationSourceIds: number[]): SetApplicationSourcesAction =>
    ({ type: SET_APPLICATION_SOURCES, applicationSourceIds });
const createSetSubstatusesAction = (substatusIds: number[]): SetSubstatusesAction => ({ type: SET_SUBSTATUSES, substatusIds });
const createSetPrimaryLanguage = (primaryLanguage: string | null) => ({ type: SET_PRIMARY_LANGUAGE, primaryLanguage });
const createSetTeamId = (teamId: number | null) => ({ type: SET_TEAM_ID, teamId });
const createSetExcludedTeamId = (excludedTeamId: number | null) => ({ type: SET_EXCLUDED_TEAM_ID, excludedTeamId });
const createSetLastStatusChangeFrom = (lastStatusChangeFrom: Date | null) => ({ type: SET_LAST_STATUS_CHANGE_FROM, lastStatusChangeFrom });
const createSetLastStatusChangeTo = (lastStatusChangeTo: Date | null) => ({ type: SET_LAST_STATUS_CHANGE_TO, lastStatusChangeTo });
const createSetFollowUpDateFrom = (followUpDateFrom: Date | null) => ({ type: SET_FOLLOW_UP_DATE_FROM, followUpDateFrom });
const createSetFollowUpDateTo = (followUpDateTo: Date | null) => ({ type: SET_FOLLOW_UP_DATE_TO, followUpDateTo });
const createSetPropertyRequired = (propertyRequired: boolean | null) => ({ type: SET_PROPERTY_REQUIRED, propertyRequired });
const createSetAmountFrom = (amountFrom: number | null) => ({ type: SET_AMOUNT_FROM, amountFrom });
const createSetAmountTo = (amountTo: number | null) => ({ type: SET_AMOUNT_TO, amountTo });
const createSetRefinancedAmountFrom = (refinancedAmountFrom: number | null) => ({ type: SET_REFINANCED_AMOUNT_FROM, refinancedAmountFrom });
const createSetRefinancedAmountTo = (refinancedAmountTo: number | null) => ({ type: SET_REFINANCED_AMOUNT_TO, refinancedAmountTo });
const createSetNominalInterestFrom = (nominalInterestFrom: number | null) => ({ type: SET_NOMINAL_INTEREST_FROM, nominalInterestFrom });
const createSetPublisherId = (publisherId: string | null) => ({ type: SET_PUBLISHER_ID, publisherId });
const createSetCustomerAdvisorsIds = (customerAdvisorsIds: number[]) => ({ type: SET_CUSTOMER_ADVISORS_IDS, customerAdvisorsIds })
const createSetGroupBy = (groupBy: ApplicantsGroupBy) => ({ type: SET_GROUP_BY, groupBy })

type CallbackToPassDispatchIn = (dispatch: Dispatch, getState: any) => void;

export type ApplicantsViewTableActionProps = typeof ApplicantsViewTableActionCreators;

export const ApplicantsViewTableActionCreators = {
    setFilters: (filter: Partial<ApplicantsViewFilter>): CallbackToPassDispatchIn => {
        return (dispatch: Dispatch): void => {
            const {
                pageNumber,
                pageSize,
                orderBy,
                orderDirection,
                products,
                loanStatus,
                followUp,
                bankId,
                advertisementSiteIds,
                advertisementIds,
                applicationSourceIds,
                substatusIds,
                primaryLanguage,
                teamId,
                excludedTeamId,
                lastStatusChangeFrom,
                lastStatusChangeTo,
                followUpDateFrom,
                followUpDateTo,
                propertyRequired,
                amountFrom,
                amountTo,
                refinancedAmountFrom,
                refinancedAmountTo,
                nominalInterestFrom,
                publisherId,
                customerAdvisorsIds,
                groupBy
            } = filter;

            if (pageNumber !== undefined) {
                dispatch(createSetPageAction(pageNumber));
            }
            if (pageSize !== undefined) {
                dispatch(createSetPageSizeAction(pageSize));
            }
            if (orderBy !== undefined && orderDirection !== undefined) {
                dispatch(createSetOrderAction(orderBy, orderDirection));
            }
            if (products !== undefined) {
                dispatch(createSetProductsAction(products));
            }
            if (loanStatus !== undefined) {
                dispatch(createSetStatusAction(loanStatus));
            }
            if (followUp !== undefined) {
                dispatch(createSetFollowUpAction(followUp));
            }
            if (bankId !== undefined) {
                dispatch(createSetBankAction(bankId));
            }
            if (advertisementSiteIds !== undefined && advertisementIds !== undefined) {
                dispatch(createSetAdvertisementsAction(advertisementSiteIds, advertisementIds));
            }
            if (applicationSourceIds) {
                dispatch(createSetApplicationSourceIds(applicationSourceIds));
            }
            if (substatusIds !== undefined) {
                dispatch(createSetSubstatusesAction(substatusIds));
            }
            if (primaryLanguage !== undefined) {
                dispatch(createSetPrimaryLanguage(primaryLanguage));
            }
            if (teamId !== undefined) {
                dispatch(createSetTeamId(teamId));
            }
            if (excludedTeamId !== undefined) {
                dispatch(createSetExcludedTeamId(excludedTeamId));
            }
            if (lastStatusChangeFrom !== undefined) {
                dispatch(createSetLastStatusChangeFrom(lastStatusChangeFrom));
            }
            if (lastStatusChangeTo !== undefined) {
                dispatch(createSetLastStatusChangeTo(lastStatusChangeTo));
            }
            if (followUpDateFrom !== undefined) {
                dispatch(createSetFollowUpDateFrom(followUpDateFrom));
            }
            if (followUpDateTo !== undefined) {
                dispatch(createSetFollowUpDateTo(followUpDateTo));
            }
            if (propertyRequired !== undefined) {
                dispatch(createSetPropertyRequired(propertyRequired));
            }
            if (amountFrom !== undefined) {
                dispatch(createSetAmountFrom(amountFrom));
            }
            if (amountTo !== undefined) {
                dispatch(createSetAmountTo(amountTo));
            }
            if (refinancedAmountFrom !== undefined) {
                dispatch(createSetRefinancedAmountFrom(refinancedAmountFrom));
            }
            if (refinancedAmountTo !== undefined) {
                dispatch(createSetRefinancedAmountTo(refinancedAmountTo));
            }
            if (nominalInterestFrom !== undefined) {
                dispatch(createSetNominalInterestFrom(nominalInterestFrom));
            }
            if (publisherId !== undefined) {
                dispatch(createSetPublisherId(publisherId));
            }
            if (customerAdvisorsIds !== undefined){
                dispatch(createSetCustomerAdvisorsIds(customerAdvisorsIds))
            }
            if (groupBy !== undefined){
                dispatch(createSetGroupBy(groupBy))
            }
        };
    },
    resetTableState: (): CallbackToPassDispatchIn => {
        return (dispatch: Dispatch): void => {
            dispatch({ type: RESET_TABLE_STATE });
        };
    }
};
