import React, {FunctionComponent, useEffect, useRef} from "react";
import "./HTMLEditorInputField.css";
import InputField, {InputFieldProps} from "../input-fields/InputField";
import {isEmptyHtmlString} from "../../helpers/isEmptyHtmlString";
import {Editor} from "@tinymce/tinymce-react";
import {ValidationProps} from "../../helpers/useValidation";
import {IProps} from "@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor";
interface HTMLEditorInputFieldProps extends InputFieldProps, ValidationProps {
    name: string;
    value: string | null | undefined;
    onValueChanged?: (value: string, name: string, dirty: boolean) => void;
}

const editorConfig: IProps['init'] = {
    height: 500,
    relative_urls: false,
    remove_script_host : false,
    menubar: false,
    valid_elements: '*[*]',
    external_plugins: {
        pluginId: 'https://cdn.motty.no/tinymce_fullpage_plugin.min.js'
    },
    plugins:
        `advlist autolink lists link image 
        code table code help wordcount fullpage`
    ,
    toolbar:
        `undo redo | blocks | bold italic backcolor |
        alignleft aligncenter alignright alignjustify |
        bullist numlist outdent indent | link image | removeformat | code | visualaid | help`
};
const HTMLEditorInputField: FunctionComponent<HTMLEditorInputFieldProps> = (props) => {
    const htmlPreivew = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (props.value && htmlPreivew.current) {
            if (!htmlPreivew.current!.shadowRoot) {
                htmlPreivew.current!.attachShadow({mode: 'open'});
            }
            htmlPreivew.current!.shadowRoot!.innerHTML = props.value;
        }
    }, [props.value]);
    
    return (
        <InputField
            className={`html-editor-input-field ${props.className}`}
            afterContent={props.afterContent}
            descriptionKey={props.descriptionKey}
            description={props.description}
            editMode={props.editMode}
            errorCode={(isEmptyHtmlString(props.value) && props.errors?.includes(props.required || '')) ? props.required : undefined}
            wrapper={props.wrapper}
            wrapperProps={props.wrapperProps}
        >
            {props.editMode ?
                <Editor
                    apiKey="9g9a6qujub52l8p4lxtqicol2mmztzn93l4pggag52ka7yfj"
                    value={props.value || undefined}
                    init={editorConfig}
                    onEditorChange={(value: string, editor) => props.onValueChanged?.(value, props.name, editor.isDirty())}
                /> :
                <div className="html-preview" ref={htmlPreivew}/>
            }
        </InputField>
    )
}
export default HTMLEditorInputField;