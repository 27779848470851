import React, { FunctionComponent, useState } from 'react';
import NumberInputField, { NumberInputFieldKind } from '../../../common/components/input-fields/NumberInputField';
import DateTimeInputField, { DateTimeInputFieldKind } from '../../../common/components/input-fields/DateTimeInputField';
import { Button } from 'react-bootstrap';
import { Translate, TranslateFunction } from 'react-localize-redux';
import { LanguageItem } from '../../../common/helpers/getLanguageItems';
import DropdownInputField from '../../../common/components/input-fields/DropdownInputField';
import { BooleanInputField } from '../../../common/components/input-fields/BooleanInputField';
import { TeamItem } from "../../../common/helpers/getTeamItems";
import TextInputField from '../../../common/components/input-fields/TextInputField';
import { UserProps } from "../../../common/interfaces/UserProps";
import { isLimitedCaseWorker } from "../../../common/helpers/limitedCaseWorkerLogic";
import * as Popper from "@popperjs/core";
import CustomerAdvisorsInputField from "../../../common/components/input-fields/CustomerAdvisorsInputField";

export interface PopoverFilters {
    amountFrom?: number | null;
    amountTo?: number | null;
    lastStatusChangeFrom?: Date | null;
    lastStatusChangeTo?: Date | null;
    followUpDateFrom?: Date | null;
    followUpDateTo?: Date | null;
    refinancedAmountFrom?: number | null;
    refinancedAmountTo?: number | null;
    nominalInterestFrom?: number | null;
    primaryLanguage?: string | null;
    teamId?: number | null;
    excludedTeamId?: number | null;
    propertyRequired?: boolean;
    publisherId?: string | null;
    customerAdvisorsIds?: number[];
}

interface FiltersPopoverProps extends PopoverFilters {
    languages: LanguageItem[];
    teams: TeamItem[];
    exclusionTeams: TeamItem[];
    userProps: UserProps;
    translate: TranslateFunction;
    onClearFilters: () => void;
    onApplyFilter: (filters: PopoverFilters) => void;
    onCancel: () => void;
}

export const FiltersPopover: FunctionComponent<FiltersPopoverProps> = (props: FiltersPopoverProps) => {
    const [filtersState, setFiltersState] = useState(props as PopoverFilters);

    const setFilter = (value: string | number | Date | boolean | null | undefined, name: string) =>
        setFiltersState((s) => ({ ...s, [name]: value }));

    const setLanguage = (value: string | number | null | undefined) => setFilter(value, 'primaryLanguage');

    const setTeamId = (value: string | number | null | undefined) =>
        setFiltersState((s) => ({ ...s, teamId: typeof (value) === 'number' ? value : null, excludedTeamId: null }));

    const setExcludedTeamId = (value: string | number | null | undefined) =>
        setFiltersState((s) => ({ ...s, teamId: null, excludedTeamId: typeof (value) === 'number' ? value : null }));

    const setCustomerAdvisors = (customerAdvisorsIds: number[]) => setFiltersState((s) => ({ ...s, customerAdvisorsIds }));

    const onClearFilters = () => {
        setFiltersState({});
        props.onClearFilters();
    };

    const applyFilters = () => {
        props.onApplyFilter(filtersState);
    };

    return (
        <div className="row">
            <div className="col-6 my-1">
                {renderDateTimeInputFieldFilter(setFilter, 'lastStatusChangeFrom', filtersState.lastStatusChangeFrom, 'LAST_STATUS_CHANGE_FROM')}
            </div>
            <div className="col-6 my-1">
                {renderDateTimeInputFieldFilter(setFilter, 'lastStatusChangeTo', filtersState.lastStatusChangeTo, 'LAST_STATUS_CHANGE_TO', 'bottom-end')}
            </div>
            <div className="col-6 my-1">
                {renderDateTimeInputFieldFilter(setFilter, 'followUpDateFrom', filtersState.followUpDateFrom, 'FOLLOW_UP_DATE_FROM')}
            </div>
            <div className="col-6 my-1">
                {renderDateTimeInputFieldFilter(setFilter, 'followUpDateTo', filtersState.followUpDateTo, 'FOLLOW_UP_DATE_TO', 'bottom-end')}
            </div>
            <div className="col-6 my-1">
                {renderNumberInputFieldFilter(setFilter, 'amountFrom', filtersState.amountFrom, NumberInputFieldKind.Money, 'AMOUNT_FROM')}
            </div>
            <div className="col-6 my-1">
                {renderNumberInputFieldFilter(setFilter, 'amountTo', filtersState.amountTo, NumberInputFieldKind.Money, 'AMOUNT_TO')}
            </div>
            <div className="col-6 my-1">
                {renderNumberInputFieldFilter(setFilter, 'refinancedAmountFrom', filtersState.refinancedAmountFrom, NumberInputFieldKind.Money, 'REFINANCED_AMOUNT_FROM')}
            </div>
            <div className="col-6 my-1">
                {renderNumberInputFieldFilter(setFilter, 'refinancedAmountTo', filtersState.refinancedAmountTo, NumberInputFieldKind.Money, 'REFINANCED_AMOUNT_TO')}
            </div>
            <div className="col-6 my-1">
                {renderNumberInputFieldFilter(setFilter, 'nominalInterestFrom', filtersState.nominalInterestFrom, NumberInputFieldKind.Percent, 'NOMINAL_INTEREST_FROM')}
            </div>
            <div className="col-6 my-1">
                {renderBooleanInputFieldFilter(setFilter, 'propertyRequired', filtersState.propertyRequired, 'PROPERTY_REQUIRED')}
            </div>
            <div className="col-12 my-1">
                <DropdownInputField
                    descriptionKey="PRIMARY_LANGUAGE"
                    editMode={true}
                    name="primaryLanguage"
                    onValueChanged={setLanguage}
                    value={filtersState.primaryLanguage}
                    items={props.languages}
                />
            </div>
            {props.teams.length && !isLimitedCaseWorker(props.userProps) &&
                (
                    <>
                        <div className="col-12 my-1">
                            <DropdownInputField
                                descriptionKey="TEAM"
                                editMode={true}
                                name="teamId"
                                onValueChanged={setTeamId}
                                value={filtersState.teamId}
                                items={props.teams}
                                keyValue={(item) => item.id}
                                displayValue={(item) => item.name}
                            />
                        </div>
                        <div className="col-12 my-1">
                            <DropdownInputField
                                descriptionKey="EXCLUDED_TEAM"
                                editMode={true}
                                name="excludedTeamId"
                                onValueChanged={setExcludedTeamId}
                                value={filtersState.excludedTeamId}
                                items={props.exclusionTeams}
                                keyValue={(item) => item.id}
                                displayValue={(item) => item.name}
                            />
                        </div>
                    </>
                )
            }
            <div className="col-12 my-1">
                <TextInputField
                    name="publisherId"
                    editMode={true}
                    onValueChanged={setFilter}
                    value={filtersState.publisherId}
                    descriptionKey="PUBLISHER_ID"
                />
            </div>
            <div className="col-12 my-1">
                <CustomerAdvisorsInputField
                    descriptionKey="CUSTOMER_ADVISOR"
                    editMode={true}
                    className={filtersState.customerAdvisorsIds?.length ? 'filter-active' : ''}
                    customerAdvisorIds={filtersState.customerAdvisorsIds}
                    onCustomerAdvisorsChanged={setCustomerAdvisors}
                    placeholder={props.translate('HEADER.FILTER_CUSTOMER_ADVISORS').toString()}
                />
            </div>
            <div className="filter-buttons">
                <Button variant="outline-primary" onClick={onClearFilters}><Translate id="CLEAR_FILTERS" /></Button>
                <Button variant="secondary" className="float-end" onClick={props.onCancel}><Translate id="CANCEL" /></Button>
                <Button variant="primary" className="float-end mx-2" onClick={applyFilters}><Translate id="APPLY" /></Button>
            </div>
        </div>
    );
};


const renderDateTimeInputFieldFilter = (
    setFilterValue: (value: Date | null, name: string) => void,
    name: string,
    value: Date | null | undefined,
    translateKey: string,
    popperPlacement?: Popper.Placement) => (
    <div className={getClassName(value)}>
        <DateTimeInputField
            name={name}
            editMode={true}
            onValueChanged={setFilterValue}
            value={value}
            descriptionKey={translateKey}
            kind={DateTimeInputFieldKind.Date}
            popperPlacement={popperPlacement}
        />
    </div>
);

const renderBooleanInputFieldFilter = (
    setFilterValue: (value: boolean | null, name: string) => void,
    name: string,
    value: boolean | null | undefined,
    translateKey: string) => (
    <div className={getClassName(value)}>
        <BooleanInputField
            name={name}
            editMode={true}
            onValueChanged={setFilterValue}
            value={value}
            descriptionKey={translateKey}
        />
    </div>
);

const renderNumberInputFieldFilter = (
    setFilterValue: (value: number | null, name: string) => void,
    name: string, value: number | null | undefined,
    kind: NumberInputFieldKind,
    translateKey: string) => (
    <div className={getClassName(value)}>
        <NumberInputField
            name={name}
            editMode={true}
            onValueChanged={setFilterValue}
            value={value}
            kind={kind}
            nullable={true}
            descriptionKey={translateKey}
        />
    </div>
);

const getClassName = (value: Date | number | boolean | null | undefined) =>
    value === null || value === undefined ? undefined : 'filter-active';