import React, {CSSProperties, FunctionComponent} from "react";
import {UserImage} from "./UserImage";
import UserSettingsButton from "./UserSettingsButton";
import {connect} from "react-redux";
import {UserProps} from "../interfaces/UserProps";

const UserInfo: FunctionComponent<UserProps> = (props) => {
    let email = '';
    let name = '';

    if (props.userData.user !== undefined) {
        email = props.userData.user.username;
        name = props.userData.user.displayName;
    }

    const style: CSSProperties = {
        textTransform: 'uppercase',
        fontSize: '12px'
    };
    
    return (
        <div className="user-info">
            <UserSettingsButton/>
            <span style={style}>{name}</span>
            <UserImage email={email} size={35}/>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    ...state.userActionsReducer
});

export default connect<UserProps, {}, {}, any>(mapStateToProps)(UserInfo);