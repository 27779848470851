import React, {useEffect, useMemo, useState} from 'react';
import {ProductType} from '../models/ProductType';
import {CommunicationTemplate} from '../models/CommunicationTemplate';
import {MessageType} from '../models/MessageType';
import {Button, Form, Modal} from 'react-bootstrap';
import {LocalizeContextProps, Translate, TranslateFunction, withLocalize} from 'react-localize-redux';
import DropdownInputField from './input-fields/DropdownInputField';
import {mapProductTypesToSelectableItems} from '../helpers/productTypeFunctions';
import GenericDropdown from './GenericDropdown';
import TextInputField from './input-fields/TextInputField';
import {postSmsMessage} from '../../applications/applicant/api/postSmsMessage';
import './send-modal.css';
import {ApplicantCommunicationModel} from '../models/ApplicantCommunicationModel';
import DraggableModalDialog from './DraggableModalDialog';
import {showToastMessage, ShowToastMessageProps} from '../actions/ToastMessagesActionCreator';
import {bindActionCreators, Dispatch} from 'redux';
import {connect} from 'react-redux';
import {
    CommunicationTemplatesDispatchProps,
    CommunicationTemplatesState
} from '../interfaces/CommunicationTemplatesProps';
import {CommunicationTemplatesActionCreator} from '../actions/CommunicationTemplatesActionCreator';
import {UserProps} from '../interfaces/UserProps';
import {getLanguageItems} from '../helpers/getLanguageItems';
import {beautifyLastName} from '../helpers/beautifyName';
import {fillSmsTemplateContentWithData} from '../helpers/SendMailAndSmsUtils';
import {Transaction} from '../../applications/applicant/models/Transaction';
import {Bank} from '../../applications/applicant/models/Bank';
import CommunicationTemplatesSelector from "./CommunicationTemplatesSelector";
import {isValueSet} from "../helpers/isValueSet";

interface NameMobileNumber {
    name: string;
    number: string;
}

interface SendSmsModalProps {
    show: boolean;
    productType?: ProductType | null;
    defaultTemplate?: string;
    applicant?: ApplicantCommunicationModel | null;
    applicationId?: number | null;
    marketingListId?: number;
    selectedMobileNumber?: string;
    applicantsCount?: number;
    incompleteFormLink?: string;
    debtInformationUpdateLink?: string;
    transactions?: Transaction[];
    banks?: Bank[];
    publicId?: string;
    onClose: (smsSent?: boolean) => void;
    onLanguageChanged?: (language: string) => void;
}

const SendSmsModal = (props: SendSmsModalProps & ShowToastMessageProps & CommunicationTemplatesState & CommunicationTemplatesDispatchProps & LocalizeContextProps & UserProps) => {
    const [selectedTemplate, setSelectedTemplate] = useState<CommunicationTemplate | undefined>(undefined);
    const [productType, setProductType] = useState(props.productType || ProductType.ConsumerLoan);
    const [content, setContent] = useState('');
    const languages = useMemo(() => getLanguageItems(props.translate, false, false), []); // eslint-disable-line react-hooks/exhaustive-deps
    const [language, setLanguage] = useState<string | null | undefined>(props.applicant?.primaryLanguage);
    const [mobileNumber, setMobileNumber] = useState<string | null>(props.selectedMobileNumber ? props.selectedMobileNumber : null);
    const [errors, setErrors] = useState<string[]>([]);
    
    useEffect(() => { setMobileNumber(props.selectedMobileNumber ? props.selectedMobileNumber : null); }, [props.selectedMobileNumber]);

    const mainMobileNumber = props.applicant ? props.applicant.mobileNumber : null;
    const secondaryMobileNumber = props.applicant ? props.applicant.additionalMobileNumber : null;

    const mobileNumbers: NameMobileNumber[] = [];

    if (isValueSet(mainMobileNumber)) { mobileNumbers.push({ name: props.translate('PRIMARY').toString(), number: mainMobileNumber }); }
    if (isValueSet(secondaryMobileNumber)) { mobileNumbers.push({ name: props.translate('SECONDARY').toString(), number: secondaryMobileNumber }); }
    const selectedMobileNumber = mobileNumbers.find((x) => x.number === mobileNumber);

    const onSelectedMobileNumberChanged = (x: NameMobileNumber) => setMobileNumber(x.number);

    const translate = (key: string) => props.translate(key).toString();

    const sendSMS = () => {
        const validationErrors =!content ? ['ContentIsRequired'] : [];
        setErrors(validationErrors);
        if (validationErrors.length) {
            return;
        }
        if (props.applicant && props.applicationId && mobileNumber) {
            const message = props.translate('SEND_SMS_SUCCESS', getApplicantPlaceholders(props.applicant, mobileNumber)).toString();
            const onFailure = () => props.showToastMessage('error', translate('SEND_SMS'), translate('SEND_SMS_ERROR'));

            postSmsMessage(content, mobileNumber, props.applicationId, props.marketingListId).then(
                (successResult) => {
                    if (successResult.success) {
                        props.showToastMessage('success', translate('SEND_SMS'), message);
                        props.onClose(true);
                    } else {
                        onFailure();
                    }
                },
                onFailure);

            if (isValueSet(language) && props.onLanguageChanged !== undefined && props.applicant.primaryLanguage !== language) {
                props.onLanguageChanged(language);
            }
        }
    };

    const onSelectedTemplateChanged = (item?: CommunicationTemplate, signature?: string) => {
        setSelectedTemplate(item);
        const templateContentWithData = fillSmsTemplateContentWithData(
            (item && item.templateContent) || '',
            signature,
            props.userData.user, 
            props.applicant, 
            props.incompleteFormLink || '',
            props.debtInformationUpdateLink, 
            props.transactions || [], 
            props.banks || [],
            props.publicId
        );
        // for now sms templates don't have signature placeholders
        setContent((templateContentWithData + ' ' + (signature || '')).trimEnd());
    };
    const onLanguageChanged = (lang: string | number | null | undefined) => setLanguage(lang === null || lang === undefined ? null : lang.toString());

    const renderMobileSelector = () => {
        return mobileNumbers.length > 1 ? (
            <React.Fragment>
                <div className="description">
                    <Translate id="SELECT_MOBILE_NUMBER" />
                </div>
                <div className="value">
                    <GenericDropdown
                        dropdownId="mobileNumber"
                        selectedItem={selectedMobileNumber}
                        displayValue={selectedMobileNumberDisplayValue}
                        items={mobileNumbers}
                        onSelectionChanged={onSelectedMobileNumberChanged}
                    />
                </div>
            </React.Fragment>
        ) : null;
    };

    return (
        <Modal show={props.show} centered={true} className="send-sms-modal" dialogAs={DraggableModalDialog} backdrop={false}>
            <form>
                <Modal.Header>
                    <Modal.Title>
                        {renderTitle(props.applicant, mobileNumber)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {renderInformation(props.applicantsCount)}
                        <div>
                            {renderMobileSelector()}
                            {renderProductType(props.productType === undefined, productType, setProductType, props.translate)}
                            <DropdownInputField
                                descriptionKey="PRIMARY_LANGUAGE"
                                editMode={true}
                                name="primaryLanguage"
                                onValueChanged={onLanguageChanged}
                                value={language || undefined}
                                items={languages}
                            />
                            <CommunicationTemplatesSelector
                                default={props.defaultTemplate}
                                type={MessageType.SMS}
                                productType={productType}
                                language={language}
                                selected={selectedTemplate}
                                onChange={onSelectedTemplateChanged}
                            />
                            <Form.Group controlId="sms-form">
                                <TextInputField
                                    descriptionKey="SMS_BODY"
                                    editMode={true}
                                    name="content"
                                    errors={errors}
                                    onValueChanged={setContent}
                                    required={'ContentIsRequired'}
                                    value={content}
                                    rows={5}
                                />
                            </Form.Group>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" className="submit-button" onClick={sendSMS}>
                        <Translate id="SEND" />
                    </Button>
                    <Button variant="secondary" onClick={() => props.onClose()}>
                        <Translate id="CANCEL" />
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

const selectedMobileNumberDisplayValue = (x: NameMobileNumber) => (`${x.name} (${x.number})`);

const renderInformation = (count?: number) => (count ? <Translate id="SEND_SMS_TO_MANY_INFO" data={{ count }} /> : null);

const renderProductType = (show: boolean, productType: ProductType, setProductType: (productType: ProductType) => void, translate: TranslateFunction) => {
    if (!show) {
        return null;
    }

    const items = mapProductTypesToSelectableItems(translate, true, false);
    const onChange = (value: string | number | null | undefined) => setProductType(value as ProductType);

    return (
        <DropdownInputField
            style={{ width: '210px' }}
            descriptionKey="PRODUCT_TYPE"
            name="products"
            value={productType}
            items={items}
            editMode={true}
            onValueChanged={onChange}
        />
    );
};

const renderTitle = (applicant: ApplicantCommunicationModel | null | undefined, mobileNumber: string | null) => (
    applicant
        ? <Translate id="SEND_SMS_TO_MODAL_TITLE" data={getApplicantPlaceholders(applicant, mobileNumber)} />
        : <Translate id="SEND_SMS_TO_MANY_TITLE" />
);

const mapStateToProps = (state: any) => ({ ...state.communicationTemplates, ...state.userActionsReducer });

const getApplicantPlaceholders = (applicant: ApplicantCommunicationModel, mobileNumber: string | null) =>
    ({ fullName: beautifyLastName(`${applicant.firstName} ${applicant.lastName}`), phoneNumber: mobileNumber });

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CommunicationTemplatesActionCreator,
    showToastMessage
} as any, dispatch);

export default connect<CommunicationTemplatesState & UserProps, ShowToastMessageProps & CommunicationTemplatesDispatchProps, SendSmsModalProps, any>
    (mapStateToProps, mapActionCreatorsToProps)(withLocalize(SendSmsModal));
