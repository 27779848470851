import React, {FunctionComponent, useEffect, useState} from "react";
import "./DesignTabContent.css";
import {getCustomerCommunicationConfig} from "../../../../../../common/helpers/settingsHelpers";
import TextInputField from "../../../../../../common/components/input-fields/TextInputField";
import {CampaignType} from "../../../../../../common/models/Campaign";
import {CampaignViewDataState} from "../../../../../reducers/campaignViewDataReducer";
import {
    CampaignViewDataActionCreators,
    CampaignViewDataActions
} from "../../../../../actions/CampaignViewDataActionCreators";
import {SettingsProps} from "../../../../../../common/interfaces/SettingsProps";
import {bindActionCreators, Dispatch} from "redux";
import {connect} from "react-redux";
import {LocalizeContextProps, Translate, withLocalize} from "react-localize-redux";
import SmsCounter from "sms-counter";
import DropdownInputField from "../../../../../../common/components/input-fields/DropdownInputField";
import {MailSender} from "../../../../../../common/helpers/useMailSender";
import {UserProps} from "../../../../../../common/interfaces/UserProps";
import {
    CampaignValidationErrors,
    checkContentTooLong,
    checkNameRequired,
    checkSenderRequired,
    checkSubjectRequired,
    checkTemplateRequired
} from "../../../helpers/validators";
import CommunicationTemplatesSelector from "../../../../../../common/components/CommunicationTemplatesSelector";
import {MessageType} from "../../../../../../common/models/MessageType";
import {CommunicationTemplate} from "../../../../../../common/models/CommunicationTemplate";
import {fillEmailTemplateContentWithData} from "../../../../../../common/helpers/SendMailAndSmsUtils";
import {getLanguageItems} from "../../../../../../common/helpers/getLanguageItems";
import MailSenderSelector from "../../../../../../common/components/MailSenderSelector";
import HTMLEditorInputField from "../../../../../../common/components/input-fields/HTMLEditorInputField";

type DesignTabStateProps = CampaignViewDataState & SettingsProps & UserProps & LocalizeContextProps;
type DesignTabDispatchProps = CampaignViewDataActions;
interface DesignTabContentProps extends DesignTabStateProps, DesignTabDispatchProps {
    editMode: boolean;
}

const DesignTabContent: FunctionComponent<DesignTabContentProps> = (props) => {
    const [errors, setErrors] = useState<string[]>(getErrors(props));
    const [selectedCommunicationTemplate, setSelectedCommunicationTemplate] = useState<CommunicationTemplate | undefined>(undefined);
    const [communicationTemplateLanguage, setCommunicationTemplateLanguage] = useState<string | null | undefined>(undefined);

    useEffect(() => {
        setErrors(getErrors(props))
    }, [props.errors]); // eslint-disable-line react-hooks/exhaustive-deps
    
    if (props.isLoading || !props.campaign) {
        return null;
    }
    const config = getCustomerCommunicationConfig(props);

    const onNameChanged = (name: string) => {
        props.editCampaign({name});
        props.setError(CampaignValidationErrors.NAME_IS_REQUIRED, checkNameRequired(name));
    }

    const onSenderChanged= (sender: MailSender, asDefault?: boolean) => {
        const campaignSender = {
            senderName: sender.name,
            senderAddress: sender.mail
        }
        props.editCampaign(campaignSender, !asDefault);
        if (!asDefault) {
            props.setError(CampaignValidationErrors.SENDER_IS_REQUIRED, checkSenderRequired(campaignSender));
        }
    }

    const onSubjectChanged= (subject: string) => {
        props.editCampaign({subject});
        props.setError(CampaignValidationErrors.SUBJECT_IS_REQUIRED, checkSubjectRequired(subject));
    }
    
    const onTemplateChanged = (template: string, asDefault?: boolean) => {
        props.editCampaign({template}, !asDefault);
        if (props.campaign && props.campaign.type === CampaignType.SMS) {
            props.setError(CampaignValidationErrors.CONTENT_TOO_LONG, checkContentTooLong(template, config.smsMaxMessages));
        }
        if (!asDefault) {
            props.setError(CampaignValidationErrors.TEMPLATE_IS_REQUIRED, checkTemplateRequired(template));
        }
    }
    
    const onCommunicationTemplateChange = (template?: CommunicationTemplate, signature?: string, asDefault?: boolean) => {
        setSelectedCommunicationTemplate(template);
        const templateContentWithData = fillEmailTemplateContentWithData(
            (template && template.templateContent) || '',
            signature,
            props.userData.user
        );
        if (template && template.subject) {
            onSubjectChanged(template.subject);
        }
        if (asDefault && (!templateContentWithData || (props.campaign && props.campaign.template))) {
            return;
        } else {
            onTemplateChanged(templateContentWithData, asDefault);
        }
    }
    
    return (
        <div className="design-tab-content">
            <div>
                <TextInputField
                    disabled={!props.editMode}
                    name="name"
                    errors={errors}
                    errorCodes={[CampaignValidationErrors.NAME_IS_REQUIRED]}
                    overrideInternalErrors={['valueMissing']}
                    editMode={true}
                    value={props.campaign.name}
                    descriptionKey="NAME"
                    onValueChanged={onNameChanged}
                />
            </div>
            {props.campaign.type === CampaignType.EMAIL &&
                <>
                    <div>
                        <MailSenderSelector
                            selected={{mail: props.campaign.senderAddress as string, name: props.campaign.senderName as string}}
                            editMode={props.editMode}
                            errors={errors}
                            errorCodes={[CampaignValidationErrors.SENDER_IS_REQUIRED]}
                            overrideInternalErrors={['valueMissing']}
                            onChange={onSenderChanged}
                        />
                    </div>
                    <div>
                        {props.editMode &&
                            <DropdownInputField
                                descriptionKey="PRIMARY_LANGUAGE"
                                editMode={true}
                                name="primaryLanguage"
                                onValueChanged={language => setCommunicationTemplateLanguage(language as string | null | undefined)}
                                value={communicationTemplateLanguage}
                                items={getLanguageItems(props.translate, false, false)}
                            />
                        }
                    </div>
                    <div>
                        {props.editMode &&
                            <CommunicationTemplatesSelector
                                selected={selectedCommunicationTemplate}
                                language={communicationTemplateLanguage}
                                type={MessageType.Email}
                                onChange={onCommunicationTemplateChange}
                            />
                        }
                    </div>
                    <div>
                        <TextInputField
                            disabled={!props.editMode}
                            name="subject"
                            errors={errors}
                            errorCodes={[CampaignValidationErrors.SUBJECT_IS_REQUIRED]}
                            overrideInternalErrors={['valueMissing']}
                            editMode={true}
                            value={props.campaign.subject}
                            descriptionKey="EMAIL_SUBJECT"
                            onValueChanged={onSubjectChanged}
                        />
                    </div>
                    <div className="html-editor-input-field-wrapper">
                        <HTMLEditorInputField 
                            name="template" 
                            editMode={props.editMode}
                            descriptionKey="CONTENT"
                            value={props.campaign.template || undefined}
                            required={CampaignValidationErrors.TEMPLATE_IS_REQUIRED}
                            errors={errors}
                            onValueChanged={(template, _, dirty) => onTemplateChanged(template, !dirty)}
                        />
                    </div>
                </>
            }
            {props.campaign.type === CampaignType.SMS &&
                <>
                    <div>
                        <TextInputField
                            disabled={!props.editMode}
                            name="content"
                            errors={errors}
                            errorCodes={[CampaignValidationErrors.CONTENT_TOO_LONG, CampaignValidationErrors.TEMPLATE_IS_REQUIRED]}
                            overrideInternalErrors={['valueMissing', 'tooLong']}
                            editMode={true}
                            value={props.campaign.template}
                            descriptionKey="CONTENT"
                            rows={4}
                            maxLength={props.campaign.type === CampaignType.SMS ? config.smsMaxCharacters : undefined}
                            onValueChanged={template => onTemplateChanged(template)}
                        />
                    </div>
                    <div className="content-length-info">
                        <span>
                            <span style={{marginRight: '4px'}}>
                                {`${(props.campaign.template || '').length} / ${config.smsMaxCharacters}`}
                            </span>
                            <Translate id="CHARACTERS"/>
                        </span>
                        <span>
                            <span
                                className={`${SmsCounter.count(props.campaign.template || '').messages > config.smsMaxMessages ? 'error' : ''}`}>
                                {SmsCounter.count(props.campaign.template || '').messages}
                            </span> 
                            <span>{` / `}</span> 
                            <span style={{marginRight: '4px'}}>{config.smsMaxMessages}</span>
                            <Translate id="MESSAGES"/>
                        </span>
                    </div>
                </>
            }
        </div>
    )
}

 const getErrors = (props: DesignTabStateProps) => {
    return Object
        .entries(props.errors)
        .reduce((activeErrors, [name, active]) => active ? activeErrors.concat(name) : activeErrors, [] as string[]);
}

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.userActionsReducer,
    ...state.campaignViewDataReducer
});

const mapActionCreatorsToProps = (dispatch: Dispatch) => bindActionCreators({
    ...CampaignViewDataActionCreators
}, dispatch);

export default connect<DesignTabStateProps, DesignTabDispatchProps>(mapStateToProps, mapActionCreatorsToProps)(withLocalize(DesignTabContent));