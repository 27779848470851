import React, {CSSProperties, FunctionComponent, ReactNode} from 'react';
import {Nav} from 'react-bootstrap';
import {connect} from 'react-redux';
import {SettingsProps} from '../interfaces/SettingsProps';
import {getSidebarBackgroundColorStyle} from '../helpers/settingsHelpers';
import {ToggleSidebarActionCreator, ToggleSidebarActionProps} from '../actions/SidebarActionCreator';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import {SidebarState} from "../reducers/sidebarReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";

export interface SidebarProps {
    id: string;
    title?: ReactNode;
    style?: CSSProperties;
    position?: 'left' | 'right';
    width?: number;
    closeIcon?: boolean;
}

type SidebarStateProps = SettingsProps & SidebarState;

const Sidebar: FunctionComponent<SidebarProps & SidebarStateProps & ToggleSidebarActionProps> = (props) => {
    const sidebarStyle = { ...getSidebarBackgroundColorStyle(props), ...(props.style || {}), '--sidebar-width': `${props.width || 209}px` };

    return (
        <React.Fragment>
            <Nav
                className={classNames('sidebar', {hidden: !props.sidebarExpanded?.includes(props.id)}, props.position || 'left')}
                style={sidebarStyle}
                id={props.id}
            >
                <div className="sidebar-title">
                    {props.title}
                    {props.closeIcon ? 
                        <button
                            className="close-sidebar"
                            onClick={() => props.toggleSidebar(props.id)}
                        >
                            <FontAwesomeIcon icon={faTimes}/>
                        </button> : null
                    }
                </div>
                {props.children}
            </Nav>
            <div className={classNames('overlay', {hidden: !props.sidebarExpanded?.includes(props.id)})}
                 onClick={() => props.toggleSidebar(props.id)}/>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    ...state.settingsActionsReducer,
    ...state.sidebarReducer
});

const mapActionCreatorsToProps = (dispatch: any) => bindActionCreators(ToggleSidebarActionCreator, dispatch);

export default connect<SidebarStateProps, ToggleSidebarActionProps, SidebarProps, any>(mapStateToProps, mapActionCreatorsToProps)(Sidebar);
